<style>
.nounExplanation {
  width: 300px;
  /* height: 50px; */
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;
  position: fixed;
  top: 280px;
  /* left:0px; */
  left: 50px;
  background-color: papayawhip;
  z-index: 999;
}
</style>

<template>
  <div>
    <!-- <p class="title">查询类型</p>
    <Row :gutter="8">
      <i-col span="24">
        <RadioGroup v-model="searchType" type="button" size="small">
          <Radio v-for="item in searchTypes" :key="item.id" :label="item.id">{{
            item.name
          }}</Radio>
        </RadioGroup>
      </i-col>
    </Row> -->
    <template v-if="searchType === 1">
      <p class="title">对账状态</p>
      <Row :gutter="8">
        <i-col span="24">
          <RadioGroup v-model="executeStatus" type="button" size="small">
            <Radio
              v-for="item in confirmStatus"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </i-col>
      </Row>

      <p class="title p-t-5">年度</p>
      <Row>
        <i-col span="24">
          <RadioGroup
            v-model="chooseYear"
            @on-change="handleChangeYear"
            size="small"
            type="button"
          >
            <Radio label="-1">全部</Radio>
            <Radio v-for="year in years" :key="year" :label="year"
              >{{ year }}年</Radio
            >
          </RadioGroup>
        </i-col>
      </Row>
      <template v-if="chooseYear !== '-1'">
        <p class="title p-t-5">季度</p>
        <Row>
          <i-col span="24">
            <RadioGroup
              v-model="chooseSenson"
              @on-change="handleChangeSeason"
              size="small"
              type="button"
            >
              <Radio label="-1">全部</Radio>
              <Radio v-for="season in seasons" :key="season" :label="season"
                >{{ season }}季度</Radio
              >
            </RadioGroup>
          </i-col>
        </Row>
        <p class="title p-t-5">月度</p>
        <Row>
          <i-col span="24">
            <RadioGroup v-model="chooseMonth" size="small" type="button">
              <Radio label="-1">全部</Radio>
              <Radio v-for="month in months" :key="month" :label="month"
                >{{ month }}月</Radio
              >
            </RadioGroup>
          </i-col>
        </Row>
      </template>
      <p class="title p-t-5">查询周期</p>
      <Row :gutter="8">
        <i-col span="10" class="p-b-5">
          <DatePicker
            placeholder="开始月份"
            v-model="chkStartDate"
            type="month"
            format="yyyy-MM"
            size="small"
          ></DatePicker>
        </i-col>
        <i-col span="10" class="p-b-5">
          <DatePicker
            placeholder="结束月份"
            format="yyyy-MM"
            size="small"
            type="month"
            v-model="chkEndDate"
            placement="bottom-end"
          ></DatePicker>
        </i-col>
        <i-col span="4">
          <icon
            id="nounExplanation"
            type="md-help-circle"
            size="18"
            style="color: #2d8cf0; cursor: pointer; margin-left: 4px"
            @click="showNounExplanation"
          ></icon>
        </i-col>
      </Row>
    </template>
    <template v-else>
      <p class="title p-t-5">查询周期</p>
      <Row :gutter="8">
        <i-col span="10" class="p-b-5">
          <DatePicker
            placeholder="开始时间"
            v-model="chkStartDate2"
            size="small"
            clearabled="false"
          ></DatePicker>
        </i-col>
        <i-col span="10" class="p-b-5">
          <DatePicker
            placeholder="结束时间"
            size="small"
            v-model="chkEndDate2"
            placement="bottom-end"
            clearabled="false"
          ></DatePicker>
        </i-col>
        <i-col span="4">
          <icon
            id="nounExplanation"
            type="md-help-circle"
            size="18"
            style="color: #2d8cf0; cursor: pointer; margin-left: 4px"
            @click="showNounExplanation"
          ></icon>
        </i-col>
      </Row>
    </template>

    <p class="title p-t-5">公司列表</p>
    <Row>
      <i-col span="24">
        <Select v-model="currentCompanyId" size="small">
          <Option value="0" v-if="companyArray.length > 1">全部</Option>
          <Option
            v-for="(company, index) in companyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>
      </i-col>
    </Row>

    <p class="title p-t-5">关键字</p>
    <Row :gutter="8">
      <i-col span="18">
        <i-input
          v-model="keyword"
          type="text"
          size="small"
          clearable
          placeholder="如：客户、品牌、编码"
        ></i-input>
      </i-col>
      <i-col span="6">
        <Button
          type="primary"
          icon="ios-search"
          long
          size="small"
          :loading="loadSearch"
          @click="search"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { getCompanyChild } from '@/api/os/company'
import { ParseDate, lastMonthDate, GetCurrentSeason } from '@/utils/dateFormat'

export default {
  data () {
    return {
      loadSearch: false,

      searchTypes: [
        { id: 1, name: '对账查询' },
        { id: 2, name: '明细查询' }
      ],
      searchType: 1,

      confirmStatus: [
        { id: -1, name: '全部' },
        { id: 0, name: '未对账' },
        { id: 1, name: '已对账' }
      ],
      years: [],
      seasons: [1, 2, 3, 4],
      months: [],
      chooseYear: '-1',
      chooseSenson: '-1',
      chooseMonth: '-1',

      companyArray: [],
      keyword: '',
      currentCompanyId: '0',
      executeStatus: -1,
      startDate: '',
      endDate: '',
      chkStartDate: '',
      chkEndDate: '',
      chkStartDate2: '',
      chkEndDate2: ''
    }
  },
  created () {
    this.initCompanyArray()
    this.initPageData()
  },
  methods: {
    initPageData () {
      const endYear = new Date().getFullYear()
      let startYear = 2020

      while (startYear <= endYear) {
        this.years.push(startYear)
        startYear++
      }
      this.handleChangeSeason()
    },
    handleChangeYear () {
      this.startDate = null
      this.endDate = null
      if (this.chooseYear === '-1') {
        this.chooseSenson = '-1'
        this.chooseMonth = '-1'
      }
    },
    handleChangeSeason () {
      this.startDate = null
      this.endDate = null
      this.chooseMonth = '-1'

      if (this.chooseSenson === '-1') {
        this.months = []
        for (let index = 1; index < 13; index++) {
          this.months.push(index)
        }
      } else {
        this.months = []
        for (let index = 1; index < 4; index++) {
          this.months.push(index + (parseInt(this.chooseSenson) - 1) * 3)
        }
      }
    },
    showNounExplanation (e) {
      if (document.getElementsByClassName('nounExplanation').length < 1) {
        var serviceDiv = document.createElement('div')
        serviceDiv.className = 'nounExplanation'

        // const title = document.createElement('p')
        // title.innerHTML = '服务费包含：'
        // serviceDiv.appendChild(title)

        const content = document.createElement('p')
        content.innerHTML =
          '记账周期检索，只会检索出当前时间内已执行的合同(部分执行也会纳入)，当前时间内签约非当前时间执行的合同不会纳入该时间段内的搜索结果'
        serviceDiv.appendChild(content)

        event.target.parentElement.appendChild(serviceDiv)
        // 3秒后自动关闭
        setTimeout(() => {
          if (serviceDiv.parentNode) {
            serviceDiv.parentNode.removeChild(serviceDiv)
          }
        }, 3000)
      } else {
        const serviceDivRemark =
          document.getElementsByClassName('nounExplanation')
        serviceDivRemark[0].parentNode.removeChild(serviceDivRemark[0])
      }
    },
    initCompanyArray () {
      // 获取交易方公司列表
      getCompanyChild({
        companyId: this.companyId,
        type:
          this.$store.getters.token.userInfo.publisherId === this.companyId
            ? 1
            : 2,
        childType: 2,
        companyType: 1
      }).then((res) => {
        let array = [
          {
            id: res.companyId,
            name: res.companyName
          }
        ]
        if (res.children.length) {
          array = array.concat(
            this.formatCompanyArray(res.children).filter(
              (x) => x.id !== res.companyId
            )
          )
        }
        this.companyArray = array
        if (res.length > 1) {
          this.currentCompanyId = '0'
        } else {
          this.currentCompanyId = this.companyId
        }

        this.search()
      })
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach((item) => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(
            this.formatCompanyArray(item.children)
          )
        }
      })
      return resultArray
    },
    search () {
      this.$store.commit(
        'set_reconciliationDemo_transactionReconciliationQuery',
        this.generateCondition
      )
      this.$store.commit('set_reconciliationDemo_pageType', 'List')
      this.$store.commit('set_reconciliationDemo_update', new Date())
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    generateCondition () {
      // 获取查询条件
      let searchStartDate = ''
      let searchEndDate = ''
      const query = {
        searchType: this.searchType,
        companyId: this.currentCompanyId,
        executeStatus: this.executeStatus,
        queryString: this.keyword
        // startDate: this.chkStartDate
        //   ? ParseDate(this.chkStartDate)
        //   : searchStartDate,
        // endDate: this.chkEndDate ? ParseDate(this.chkEndDate) : searchEndDate
      }
      if (this.searchType === 1) {
        if (this.chooseMonth !== '-1') {
          // 取月度
          const flag = '-' + (this.chooseMonth < 10 ? '0' : '')
          searchStartDate = this.chooseYear + flag + this.chooseMonth + '-01'
          searchEndDate = lastMonthDate(this.chooseYear, this.chooseMonth)
        } else if (this.chooseSenson !== '-1') {
          // 取季度
          const startMonth = 1 + (parseInt(this.chooseSenson) - 1) * 3
          const startYear =
            this.chooseYear + '-' + (startMonth < 10 ? '0' : '')
          searchStartDate = startYear + startMonth + '-01'
          searchEndDate = lastMonthDate(this.chooseYear, startMonth + 2)
        } else if (this.chooseYear !== '-1') {
          // 取年度
          searchStartDate = this.chooseYear + '-01-01'
          searchEndDate = this.chooseYear + '-12-31'
        } else {
          searchStartDate = '2020-01-01'
          searchEndDate = new Date().getFullYear() + '-12-31'
        }

        query.startDate = this.chkStartDate
          ? ParseDate(this.chkStartDate)
          : searchStartDate
        query.endDate = this.chkEndDate
          ? lastMonthDate(
            this.chkEndDate.getFullYear(),
            this.chkEndDate.getMonth() + 1
          )
          : searchEndDate
      } else {
        query.startDate = ParseDate(this.chkStartDate2)
        query.endDate = ParseDate(this.chkEndDate2)
      }

      return query
    }
  },
  watch: {
    searchType (val) {
      if (val === 2) {
        this.chkStartDate2 = this.chkStartDate2 || GetCurrentSeason().startDate
        this.chkEndDate2 = this.chkEndDate2 || GetCurrentSeason().endDate
      }
      this.search()
    }
  }
}
</script>
